<template>
  <div class="transportation-accounts-vehicle">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-vehicle'">

      <v-form v-if="currentVehicle && !currentVehicleError" @submit.prevent="save()" ref="form">
        <div class="layout justify-end">
          <v-btn color="warning mr-2" flat @click.stop="loadParentView" :disabled="apiInProgress">Cancel</v-btn> <v-btn color="primary" type="submit" v-if="hasUserType('admin') || hasTransportationRole('OWNER')" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress">Save</v-btn>
        </div>
        <v-layout row wrap>
          <v-flex sm7 pa-2>
            <v-layout row align-center>
              <v-flex sm7 offset-sm5 pa-2>
                <div>
                  <v-btn @click="viewQrCode" class="ml-0"><v-icon>far fa-qrcode</v-icon> &nbsp; View/Download Quickpay QR code</v-btn>
                  <v-dialog v-model="modalQrcode" width="880px">
                    <v-card>
                      <v-card-title class="justify-center dialog-close-container">
                        Quickpay sticker for licence plate: {{currentVehicle.licensePlateNumber}}
                        <v-btn class="dialog-close" color="primary" flat icon @click="modalQrcode = false"><v-icon>far fa-times</v-icon></v-btn>
                      </v-card-title>
                      <v-card-text class="px-2 pb-2 pt-0 layout column align-center justify-center">
                        <v-alert v-model="qrCodeErrorOccurred" type="error">{{qrCodeErrorOccurred}}</v-alert>
                        <div>
                          <div v-if="!qrCodeErrorOccurred" id="qrCodeContainer" ref="qrCodeContainer" class="qrcode-container">
                            <div class="qrcode-logo mt-2 layout row justify-space-around">
                              <div class="qrcode-option qrcode-text">OPTION 1</div>
                              <div>
                                <img v-if="logoImage" :src="logoImage" style="height: 45px" alt="SmartTransit" /><em><strong>Quickpay</strong></em>
                              </div>
                              <div class="qrcode-option qrcode-text">OPTION 2</div>
                            </div>
                            <div class="layout row justify-start">
                              <div style="width: 50%; border-right: 1px solid #000; position: relative" class="ml-2 mr-3 my-4 layout row align-start justify-start">
                                <div>
                                  <img v-if="featurePhoneExample" :src="featurePhoneExample" style="width: 70px" alt="Feature phone" />
                                </div>
                                <div style="padding-left: 17px;" class="qrcode-text">
                                  <p>Dial Shortcode: *447*558#</p>
                                  <p>Enter Bus ID: {{quickpayShortcodeId}}</p>
                                </div>
                              </div>
                              <div style="width: 50%" class="mr-2 ml-3 my-4 layout row align-start justify-start">
                                <div>
                                  <img v-if="smartPhoneExample" :src="smartPhoneExample" style="width: 100px" alt="Smartphone" />
                                </div>
                                <div>
                                  <div style="padding-left: 17px">
                                    <p><span class="qrcode-text qrcode-text--smaller">Go to</span><br />{{quickpayHomeUrl}}</p>
                                    <p class="qrcode-text qrcode-text--smaller">Scan the QR code below</p>
                                  </div>
                                  <canvas id="qrCode" ref="qrCode" />
                                  <div style="padding-left: 17px">
                                    <small><a :href="quickpayQrcodeUrl" target="_blank" style="color: #000; text-decoration: none">{{quickpayQrcodeUrl}}</a></small>
                                  </div>
                                  <div class="mt-2 qrcode-text qrcode-text--smaller" style="padding-left: 17px; padding-right: 17px;">Or enter bus ID: {{quickpayShortcodeId}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <download-button
                            :buttonAttributes="{color: 'primary'}"
                            label="Download as image"
                            download-type="image/png"
                            label-icon="far fa-download"
                            :data-is-already-blob="true"
                            :on-download="downloadQuickpaySticker"
                            :file-name="quickpayStickerFilename"
                        />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong v-if="!currentVehicle.transportationEquipmentId">Set from available equipment</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <div>
                  <v-btn v-if="currentVehicle.transportationEquipmentId" @click="disconnectEquipment()">
                    <v-icon small>fa fa-unlink</v-icon> &nbsp; Disconnect Equipment
                  </v-btn>
                  <v-autocomplete
                      outline
                      dense
                      clearable
                      no-filter
                      v-if="!currentVehicle.transportationEquipmentId"
                      @change="availableEquipmentChangeHandle"
                      :loading="isEquipmentListLoading"
                      :items="availableEquipment"
                      :search-input.sync="availableEquipmentSearch"
                      no-data-text="No data available, try new search terms"
                      placeholder="Search by device id, inventory id, hotspot ssid, OS version"
                  />
                </div>
                <div class="mt-2" v-if="currentVehicle.transportationEquipment || currentVehicle.selectedAvailableEquipment">
                  <div v-if="currentVehicle.transportationEquipment && !currentVehicle.selectedAvailableEquipment">
                    <v-icon small>fas fa-tablet-android-alt</v-icon> &nbsp;Current device: {{currentVehicle.idLabel}}
                  </div>
                  <div v-if="currentVehicle.selectedAvailableEquipment">
                    <v-alert
                        v-model="toggleSelectedAvailableEquipment"
                        color="info">
                      <span v-show="!currentVehicle.selectedAvailableEquipment.inventoryId">
                        <v-icon small dark>fas fa-tablet-android-alt</v-icon> &nbsp; Change to:&nbsp; <small>(device id)</small> {{currentVehicle.selectedAvailableEquipment.deviceId}}</span>
                      <span v-show="currentVehicle.selectedAvailableEquipment.inventoryId">
                        <small>(inventory id)</small> {{currentVehicle.selectedAvailableEquipment.inventoryId}}
                      </span>
                    </v-alert>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Licence plate number</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-text-field v-model="currentVehicle.licensePlateNumber" pb-2 required :readonly="!currentVehicle.isEditable" />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Agency</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-select
                  outline
                  hide-details
                  :disabled="!currentVehicle.isEditable"
                  v-model="currentVehicle.agencyId"
                  :items="agencies"
                />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Vehicle type</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-select
                  outline
                  hide-details
                  @change="onFilterByTransportType"
                  :disabled="!currentVehicle.isEditable"
                  v-model="currentVehicle.transportType"
                  :items="transportTypes"
                />
              </v-flex>
            </v-layout>
            <v-layout row :class="currentVehicle.usesRoutes ? 'align-end' : 'align-center'">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Routes</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <div class="layout justify-start">
                  <v-tooltip top class="mr-2">
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fa fa-info-circle</v-icon>
                    </template>
                    <span>Enable if this vehicle has defined routes that it always runs on at specified times.<br />
                      SmartTransit vehicle types must always have a route.</span>
                  </v-tooltip>
                  <v-switch style="max-width: 138px" v-model="currentVehicle.usesRoutes" :disabled="!currentVehicle.isEditable || isSmartTransitVehicle()" label="Uses Routes" />
                </div>
                <div class="layout justify-start">
                  <v-btn v-show="currentVehicle.usesRoutes" class="ml-0" :to="{ name: 'transportation-accounts-vehicle-itinerary' }" :disabled="apiInProgress || !vehicleId || vehicleId === '0'">View/Edit Route Itinerary</v-btn>
                  <v-tooltip top class="ml-2" v-if="!vehicleId || vehicleId === '0'">
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fa fa-info-circle</v-icon>
                    </template>
                    <span>Create a vehicle in order to add itinerary</span>
                  </v-tooltip>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Current driver</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-select
                  clearable
                  outline
                  hide-details
                  :disabled="!currentVehicle.isEditable"
                  class="mt-2"
                  v-model="currentVehicle.currentDriver"
                  :items="availableDrivers"
                  placeholder="Select driver"
                  :rules="[(v) => (!v || v !== currentVehicle.currentAssistant || 'Current driver and current assistant cannot be identical')]"
                />
              </v-flex>
            </v-layout>
            <v-layout row align-center v-if="!isSmartTransitVehicle()">
              <v-flex sm5 pa-2 class="text-xs-right">
                <strong>Current assistant</strong>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-select
                  clearable
                  outline
                  hide-details
                  :disabled="!currentVehicle.isEditable"
                  class="mt-2"
                  v-model="currentVehicle.currentAssistant"
                  :items="availableDrivers"
                  placeholder="Select assistant"
                  :rules="[(v) => (!v || v !== currentVehicle.currentDriver || 'Current driver and current assistant cannot be identical')]"
                />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="layout justify-end align-center">
                <v-tooltip top class="mr-2">
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>If disabled, SMS text alerts will NOT be sent to the backup alert number in the event of device internet failure.<br />
                If no number or driver is specified, no warning email be sent to super admins either</span>
                </v-tooltip>
                <div><strong>SMS text backup alerts</strong></div>
              </v-flex>
              <v-flex sm7 pa-2>
                <v-switch v-model="currentVehicle.metadata.ignoreBackupEquipment" :disabled="!currentVehicle.isEditable" label="Disable" />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm5 pa-2 class="layout justify-end align-center">
                <div><v-tooltip top class="mr-2">
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>When a passenger purchases or reconfirms their ticket, the vehicle device is alerted<br />
                  If the tablet is non-operational or fails (due to internet or network issues) this number is where the SMS text shall sent as an alternative</span>
                </v-tooltip>
                </div>
                <div class="text-xs-right layout shrink"><strong>SMS text backup alert number</strong></div>
              </v-flex>
              <v-flex sm7 pa-2>
                <phone-number-field
                  :readonly="!currentVehicle.isEditable"
                  v-model="currentVehicle.metadata.backupEquipmentNumber"
                  :child-props="{ placeholder: 'If blank, driver\'s phone number is used' }"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm5 pa-2>
            <div class="mb-4">
              <h3>Hours Of Operation</h3>
              <hours-of-operation enable-templates enable-timezone v-model="currentVehicle.hoursOfOperationTemplateAndData" />
            </div>
            <div class="mb-2">
              <h3>Vehicle Information</h3>
              <v-list two-line v-if="currentVehicle.metadata">
                <template v-for="(item, prop) in currentVehicle.metadata.vehicle">
                  <v-list-tile :key="`vehicle-list-${prop}`">
                    <v-list-tile-content>
                      <v-text-field v-if="prop !== 'engine'" :placeholder="prop === 'type' ? 'Eg. Toyota Hiace' : ''" v-model="currentVehicle.metadata.vehicle[prop]" :label="prop" />
                      <v-select
                          v-if="prop === 'engine'"
                          :items="['Gas', 'Diesel', 'Electric']"
                          :label="prop"
                          v-model="currentVehicle.metadata.vehicle[prop]"
                      />
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn small fab flat @click="removeVehicleMetadata(prop)">
                        <v-icon>
                          fas fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="`vehicle-divider-${prop}`" />
                </template>
              </v-list>
            </div>
            <div>
              <div class="layout justify-space-between align-center">
                <h3>Other Information</h3>
                <v-btn small fab @click="addMetadata()"><v-icon small>fas fa-plus</v-icon></v-btn>
              </div>
              <v-list two-line dense v-if="currentVehicle.metadata.other">
                <template v-for="(item, prop) in currentVehicle.metadata.other">
                  <v-list-tile :key="`list-${prop}`">
                    <v-list-tile-content>
                      <v-text-field v-model="currentVehicle.metadata.other[prop]" :label="prop"></v-text-field>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn small fab flat @click="removeMetadata(prop)">
                        <v-icon small>
                          fas fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="`divider-${prop}`" />
                </template>
              </v-list>
            </div>
          </v-flex>
        </v-layout>

        <div class="layout justify-end">
          <v-btn color="warning mr-2" flat @click.stop="loadParentView" :disabled="apiInProgress">Cancel</v-btn> <v-btn color="primary" type="submit" v-if="hasUserType('admin') || hasTransportationRole('OWNER')" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress">Save</v-btn>
        </div>
      </v-form>
      <v-alert
          :value="currentVehicleError"
          type="error"
      >
        <p>{{currentVehicleError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'transportation-accounts-vehicles' }">Return to vehicles list</router-link></p>
      </v-alert>
    </div>
    <router-view
        :force-root-view-refresh="forceRootViewRefresh"
        :signed-in-user="signedInUser"
        :has-user-type="hasUserType"
        :has-transportation-role="hasTransportationRole">
    </router-view>
  </div>
</template>

<style scoped>
  .qrcode-container {
    border: 1px solid #000;
    width: 100%;
    height: 430px;
  }
  .qrcode-or-container {
    position: absolute;
    top: 47%;
    right: -20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #1976d2;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }
  .qrcode-text {
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
  }
  .qrcode-text--smaller {
    font-size: 1.5em;
    line-height: 1em;
  }
  .qrcode-logo em {
    color: #1976d2;
    text-transform: uppercase;
  }
  .qrcode-option {
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #1976d2;
    color: #fff;
  }
</style>

<script>
import src from './src'
export default src
</script>
